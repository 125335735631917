import React from "react"
import {Link} from "gatsby"

const authorCard = props => {
  return (
    <>
      {props.type !== "link" &&
        (props.type === "team" ? (
          <div className="author-wrap link position-relative">
            <img
              src={props.picture.url}
              alt="author"
              className="position-absolute"
              height="72"
              width="72"
            />
            <span className="alt">
              <h4> {props.name} </h4>
            </span>
          </div>
        ) : (
          <Link to={`/blog/author/${props.slug}`} className="no-text-decoration">
            <div className="author-wrap position-relative" style={props.style}>
              <img
                src={props.picture.url}
                alt="author"
                height="64"
                width="64"
              />
              <div className="author-wrap-details">
                <span className="alt">
                  <h4> {props.name} </h4>
                </span>
                <span className="p12">
                  <p> {props.title} </p>
                </span>
              </div>
            </div>
          </Link>
        ))}

      {props.type === "link" && (
        <Link to={`/blog/author/${props.slug}`} className="no-text-decoration">
          <div
            className="author-wrap link position-relative"
            style={props.style}
          >
            <img
              src={props.picture.url}
              alt="author"
              className="position-absolute"
              height="72"
              width="72"
            />
            <span className="alt">
              <h4> {props.name} </h4>
            </span>
          </div>
        </Link>
      )}
    </>
  )
}

export default authorCard
